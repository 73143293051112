<template>
  <div
    class="payment-create create-form"
    v-if="getPermission('payment:create')"
  >
    <v-form
      ref="paymentForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="7" class="pt-0">
                  <v-skeleton-loader
                    v-if="pageLoading"
                    class="custom-skeleton custom-skeleton-full-width"
                    type="button"
                  ></v-skeleton-loader>
                  <h1 v-else class="form-title d-flex">
                    <template v-if="isUpdateMode"> Update Payment </template>
                    <template v-else> New Payment </template>
                  </h1>
                </v-col>
                <v-col cols="5" class="pt-0 text-right">
                  <v-btn
                    v-on:click="goBack"
                    :disabled="formLoading || pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="!formValid || formLoading || pageLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="onSubmit"
                    color="cyan"
                  >
                    Save Payment
                  </v-btn>
                  <v-menu
                    v-if="!isUpdateMode"
                    transition="slide-y-transition"
                    bottom
                    content-class="custom-menu-list"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="!formValid || formLoading || pageLoading"
                        :loading="formLoading"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Save and... <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item v-on:click="onSubmit('mark_as_paid')">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-currency-usd</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Mark as Paid</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item v-on:click="onSubmit('mark_as_unpaid')">
                        <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                          <v-icon class="icon-default-blue"
                            >mdi-currency-usd-off</v-icon
                          >
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-500 font-size-14"
                          >Mark as Unpaid</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-col>
              </v-row>
            </div>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <div class="p-5 pt-0">
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-container fluid>
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Invoice **</label
                      >
                      <v-autocomplete
                        hint="** List contains only SENT invoices"
                        persistent-hint
                        :items="invoiceList"
                        v-model.trim="paymentCreate.invoice"
                        dense
                        filled
                        item-color="cyan"
                        color="cyan"
                        label="Invoice"
                        :disabled="isPageLoading"
                        solo
                        flat
                        item-value="id"
                        item-text="title"
                        v-on:change="selectInvoice"
                        :filter="searchInvoice"
                        :rules="[
                          validateRules.required(
                            paymentCreate.invoice,
                            'Invoice'
                          ),
                          validateRules.minLength(
                            paymentCreate.invoice,
                            'Invoice',
                            1
                          ),
                          validateRules.maxLength(
                            paymentCreate.invoice,
                            'Invoice',
                            100
                          ),
                        ]"
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-title>
                              No Sent Invoice(s) Found.
                            </v-list-item-title>
                          </v-list-item>
                        </template>
                        <template
                          v-slot:selection="{ attr, on, item, selected }"
                        >
                          {{ item.title }} ({{ item.barcode }})
                        </template>
                        <template v-slot:item="{ item }">
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-size-16 font-weight-500 px-0"
                              >{{ item.title }} ({{
                                item.barcode
                              }})</v-list-item-title
                            >
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip>
                              {{
                                $accountingJS.formatMoney(item.pending_amount)
                              }}
                            </v-chip>
                          </v-list-item-action>
                        </template>
                      </v-autocomplete>
                    </div>
                    <div v-if="paymentCreate.invoice">
                      <v-row>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@5"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Billing address</label
                            >
                            <div v-if="lodash.isEmpty(billing) === false">
                              <p
                                v-if="billing.unit_no"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.unit_no }}
                              </p>
                              <p
                                v-if="billing.street_1"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.street_1 }}
                              </p>
                              <p
                                v-if="billing.street_2"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.street_2 }}
                              </p>
                              <p
                                v-if="billing.zip_code"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ billing.zip_code }}
                              </p>
                              <p
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                <span v-if="CityBillingName"
                                  >{{ CityBillingName }},
                                </span>
                                <span v-if="StateBillingName"
                                  >{{ StateBillingName }},
                                </span>
                                <span v-if="CountryBillingName">{{
                                  CountryBillingName
                                }}</span>
                              </p>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@5"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Property address</label
                            >
                            <div v-if="lodash.isEmpty(property) === false">
                              <p
                                v-if="property.unit_no"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ property.unit_no }}
                              </p>
                              <p
                                v-if="property.street_1"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ property.street_1 }}
                              </p>
                              <p
                                v-if="property.street_2"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ property.street_2 }}
                              </p>
                              <p
                                v-if="property.zip_code"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ property.zip_code }}
                              </p>
                              <p
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                <span v-if="CityName">{{ CityName }}, </span>
                                <span v-if="StateName">{{ StateName }}, </span>
                                <span v-if="CountryName">{{
                                  CountryName
                                }}</span>
                              </p>
                            </div>
                          </template>
                        </v-col>
                        <v-col cols="4">
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton custom-card-heading"
                            type="card-heading, text@3"
                          ></v-skeleton-loader>
                          <template v-else>
                            <label class="font-weight-700 font-size-16"
                              >Contact details</label
                            >
                            <div>
                              <p
                                v-if="attention"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ attention }}
                              </p>
                              <p
                                v-if="contact_person.primary_phone"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_phone }}
                              </p>
                              <p
                                v-if="contact_person.primary_email"
                                class="mb-0 font-size-16 custom-nowrap-ellipsis"
                              >
                                {{ contact_person.primary_email }}
                              </p>
                            </div>
                          </template>
                        </v-col>
                      </v-row>
                    </div>
                  </v-container>
                </v-col>
                <v-col cols="5" class="pb-0">
                  <v-container fluid>
                    <table class="width-100">
                      <tr>
                        <td
                          colspan="3"
                          class="font-weight-700 font-size-16 pb-2"
                        >
                          Basic details
                        </td>
                      </tr>
                      <tr>
                        <td class="font-size-16 py-2" width="200">
                          Payment number
                        </td>
                        <td
                          class="font-weight-700 font-size-16 pb-2"
                          width="150"
                          :colspan="isUpdateMode ? 2 : 1"
                        >
                          <v-skeleton-loader
                            v-if="pageLoading"
                            class="custom-skeleton"
                            type="text"
                          ></v-skeleton-loader>
                          <template v-else>{{
                            paymentCreate.barcode
                          }}</template>
                        </td>
                        <td v-if="!isUpdateMode" class="font-size-16 pb-2">
                          <v-btn
                            text
                            small
                            :disabled="isPageLoading"
                            class="custom-bold-button"
                            v-on:click="barcodeDialog = true"
                            color="cyan"
                          >
                            Change
                          </v-btn>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Reference #</td>
                        <td colspan="2" class="py-2">
                          <v-text-field
                            dense
                            color="cyan"
                            filled
                            :disabled="isPageLoading"
                            v-model.trim="paymentCreate.reference"
                            label="Reference #"
                            :rules="[
                              validateRules.minLength(
                                paymentCreate.reference,
                                'Reference #',
                                1
                              ),
                              validateRules.maxLength(
                                paymentCreate.reference,
                                'Reference #',
                                100
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Payment Date</td>
                        <td colspan="2" class="py-2">
                          <DatePicker
                            solo
                            :pageLoading.sync="isPageLoading"
                            :placeholder="'Payment Date'"
                            :mandatory="MandatoryPaymentDate"
                            v-on:update:date-picker="setPaymentDate"
                          ></DatePicker>
                        </td>
                      </tr>
                      <tr>
                        <td class="pb-2 font-size-16">Payment mode</td>
                        <td colspan="2" class="py-2">
                          <v-select
                            :items="paymentModeList"
                            dense
                            filled
                            solo
                            flat
                            hide-details
                            :rules="[
                              validateRules.required(
                                paymentCreate.payment_mode,
                                'Payment mode'
                              ),
                            ]"
                            :disabled="isPageLoading"
                            v-model.trim="paymentCreate.payment_mode"
                            append-outer-icon="mdi-cog"
                            v-on:click:append-outer="
                              managePaymentModeDialog = true
                            "
                            label="Payment mode"
                            color="cyan"
                            item-text="text"
                            item-value="id"
                            item-color="cyan"
                          ></v-select>
                        </td>
                      </tr>
                      <template v-if="false">
                        <tr
                          v-for="(field, index) in customFields"
                          :key="index"
                          :class="{
                            'custom-border-bottom': customFields[index + 1],
                          }"
                        >
                          <td class="pb-2 font-size-16">
                            {{ field.field_name }}
                          </td>
                          <td colspan="2" class="py-2">
                            <CustomField
                              :field.sync="field"
                              :pageLoading.sync="isPageLoading"
                              :index.sync="index"
                            ></CustomField>
                          </td>
                        </tr>
                        <tr v-if="false && customFields.length < 5">
                          <td colspan="3">
                            <v-btn
                              :disabled="isPageLoading"
                              class="my-2 mr-2 custom-bold-button white--text"
                              color="cyan"
                              small
                              solo
                              flat
                              v-on:click="customFieldDialog = true"
                              ><strong class="font-size-14"
                                >Add Custom Field
                              </strong>
                            </v-btn>
                            <template v-if="customFieldDialog">
                              <CreateCustomField
                                :fieldModule="4"
                                v-on:update:custom-field="
                                  getAttributes(['custom_field'])
                                "
                                v-on:close:custom-field="
                                  customFieldDialog = false
                                "
                              ></CreateCustomField>
                            </template>
                          </td>
                        </tr>
                      </template>
                    </table>
                  </v-container>
                </v-col>
                <v-col cols="12">
                  <PaymentInformation
                    :updateData.sync="updateData.PaymentInformation"
                    :pageLoading.sync="isPageLoading"
                  ></PaymentInformation>
                </v-col>
                <v-col cols="12">
                  <PaymentAttachment
                    :disableNotification="disableNotification"
                    :updateData.sync="updateData.PaymentAttachment"
                    :pageLoading.sync="isPageLoading"
                  ></PaymentAttachment>
                </v-col>
              </v-row>
            </div>
            <!-- </perfect-scrollbar> -->
          </v-container>
        </v-col>
      </v-row>
    </v-form>
    <ManagePaymentMode
      :commonDialog.sync="managePaymentModeDialog"
      :paymentMode.sync="paymentModeList"
      v-on:close-dialog="managePaymentModeDialog = false"
      v-on:get-payment-mode="getAttributes(['payment_modes'])"
    ></ManagePaymentMode>
    <BarcodeSetting
      endpoint="payment"
      :barcodeDialog="barcodeDialog"
      :dialogWidth="600"
      :barcodeSetting.sync="barcodeSetting"
      v-on:close:barcode-dialog="barcodeDialog = false"
    ></BarcodeSetting>
  </div>
</template>

<script>
import CreatePaymentMixin from "@/core/lib/payment/create.payment.mixin";
import BarcodeSetting from "@/view/pages/partials/Barcode-Setting.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CustomField from "@/view/pages/custom-field/Custom-Field.vue";
import CreateCustomField from "@/view/pages/custom-field/Create-Custom-Field.vue";
import PaymentAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import PaymentInformation from "@/view/pages/partials/Payment-Information.vue";
import ManagePaymentMode from "@/view/pages/partials/Manage-Payment-Mode.vue";

export default {
  mixins: [CreatePaymentMixin],
  name: "payment-create",
  title: "Create Payment",
  components: {
    BarcodeSetting,
    DatePicker,
    CustomField,
    CreateCustomField,
    ManagePaymentMode,
    PaymentAttachment,
    PaymentInformation,
  },
};
</script>
