<template>
  <Dialog :commonDialog="fieldDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>New Custom Field</template>
    <template v-slot:body>
      <v-form
        ref="customFieldForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="onSubmit"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model.trim="customField.module"
                :items="moduleList"
                dense
                :disabled="disableModule"
                filled
                outlined
                label="Module"
                item-color="cyan"
                color="cyan"
                item-text="title"
                item-value="value"
                hide-details
                :rules="[validateRules.required(customField.module, 'Module')]"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="customField.field_name"
                dense
                filled
                color="cyan"
                label="Field name"
                outlined
                hide-details
                :rules="[
                  validateRules.required(customField.field_name, 'Field name'),
                  validateRules.minLength(
                    customField.field_name,
                    'Field name',
                    1
                  ),
                  validateRules.maxLength(
                    customField.field_name,
                    'Field name',
                    100
                  ),
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-autocomplete
                v-model.trim="customField.field_type"
                :items="customFieldList"
                dense
                filled
                outlined
                label="Field type"
                item-color="cyan"
                color="cyan"
                hide-details
                item-text="title"
                item-value="value"
                :rules="[
                  validateRules.required(customField.field_type, 'Module'),
                ]"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model.trim="customField.default_value"
                dense
                filled
                color="cyan"
                label="Default value"
                outlined
                hide-details
                :rules="[
                  validateRules.minLength(
                    customField.default_value,
                    'Field name',
                    1
                  ),
                  validateRules.maxLength(
                    customField.default_value,
                    'Field name',
                    100
                  ),
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                v-model.trim="customField.mandatory"
                inset
                hide-details
                label="Mandatory"
                :value="1"
                color="cyan"
                class="mt-0"
              ></v-switch>
            </v-col>
            <v-col v-if="isValidList" cols="12" md="12">
              <v-text-field
                v-model.trim="listValue"
                dense
                ref="listvalue"
                filled
                color="cyan"
                label="List Value"
                placeholder="Just Type and hit enter"
                outlined
                hide-details
                @keydown.enter="onEnter"
                :rules="[
                  validateRules.minLength(listValue, 'List', 1),
                  validateRules.maxLength(listValue, 'List', 100),
                ]"
              ></v-text-field>
            </v-col>
            <v-col v-if="customField.list.length > 0" cols="12" md="12">
              <!-- <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 40vh; position: relative"
              > -->
              <table
                class="width-100 font-size-16 custom-grey-border font-weight-500"
              >
                <tr
                  v-for="(field, index) in customField.list"
                  :key="index"
                  :class="{
                    'custom-border-bottom': customField.list[index + 1],
                  }"
                >
                  <td class="py-2 px-4 custom-border-right" align="left">
                    {{ field }}
                  </td>
                  <td class="py-2 px-4" align="center">
                    <v-btn
                      :disabled="formLoading"
                      class="mx-2 custom-bold-button white--text"
                      color="red lighten-1"
                      v-on:click="removeField(index)"
                      fab
                      small
                    >
                      <v-icon color="">mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </table>

              <!-- </perfect-scrollbar> -->
            </v-col>
            <v-col cols="12" md="12">
              <h3 class="font-size-16 font-weight-500 color-custom-blue">
                All custom fields can be edited and reordered in Settings >
                Custom Fields.
              </h3>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="onSubmit"
      >
        Save
      </v-btn>

      <v-btn
        :disabled="formLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close:custom-field', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { POST } from "@/core/services/store/request.module";
export default {
  name: "create-custom-field",
  mixins: [ValidationMixin],
  props: {
    fieldModule: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return {
      formValid: true,
      formLoading: false,
      fieldDialog: true,
      disableModule: false,
      listValue: null,
      customField: {
        module: 1,
        field_name: null,
        field_type: "text",
        default_value: null,
        list: new Array(),
        mandatory: 0,
      },
      moduleList: [
        { title: "Quotation", value: 1 },
        { title: "Invoice", value: 2 },
        { title: "Ticket", value: 3 },
        { title: "Payment", value: 4 },
      ],
      customFieldList: [
        { title: "Text", value: "text" },
        { title: "Textarea", value: "textarea" },
        { title: "Checkbox", value: "checkbox" },
        { title: "Dropdown", value: "dropdown" },
        { title: "Date", value: "date" },
        { title: "Time", value: "time" },
        { title: "Radio", value: "radio" },
      ],
    };
  },
  components: {
    Dialog,
  },
  methods: {
    onSubmit() {
      const _this = this;
      if (!_this.$refs.customFieldForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, { url: "custom-field", data: _this.customField })
        .then((response) => {
          _this.$emit("update:custom-field", response.data);
          _this.$emit("close:custom-field", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    onEnter() {
      let value = this.lodash.lowerCase(this.listValue);
      if (value && !this.lodash.includes(this.customField.list, value)) {
        this.customField.list.push(value);
      }
      this.listValue = null;
    },
    removeField(index) {
      this.customField.list.splice(index, 1);
    },
  },
  mounted() {
    if (this.fieldModule > 0) {
      this.disableModule = true;
      this.customField.module = this.lodash.toSafeInteger(this.fieldModule);
    }
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 50);
    },
    isValidList() {
      let validListType = ["checkbox", "dropdown", "radio"];
      return this.lodash.includes(validListType, this.customField.field_type);
    },
  },
};
</script>
